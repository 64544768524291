import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import ServiceAreas from "../../components/serviceAreas"
import SubServicesAlt from "../../components/subServicesAlt"
import HeroSection from "../../components/heroSection"
import Testimonials from "../../components/testimonials"
import Education from "../../components/education"

function Toilets() {
  const data = useStaticQuery(graphql`
    query toiletImages {
      toilet: file(relativePath: { eq: "toilet-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Toilet Repair & Installation"
        description={`Our knowledgable & certified team can handle your toilet repair or installation quickly. Call now to speak with a master plumber and get a 100% free quote.`}
      />
      <HeroSection
        h1={`Toilet Services`}
        h2={`Have a Master Plumber Fix Your Toilet`}
        h3={`A working toilet is a must-have. If your toilet is running, leaky, clogged, or not flushing properly, Klein Plumbing Services is able to help.`}
        p1={`Our experienced team can be at your home quickly to get your toilet working again. We've repaired countless toilets over three decades of serving the North Houston area, which is why our service is fast, professional, and affordable.`}
        heroImg={data.toilet.childImageSharp.fluid}
        heroImgAltTag={`Toilet Repair and Installation`}
      />
      <Education
        mainTitle={`Signs Your Toilet Needs Repairing`}
        titleOne={`It Regularly Clogs`}
        descriptionOne={`Occasional toilet clogs are normal with regular use, but if you're experiencing clogs more than once a week, there's likely a larger issue. Parts that manage flushing may have worn out or your plumbing may have partial blockage.`}
        titleTwo={`It Has Leaks or Cracks`}
        descriptionTwo={`Your toilet should not be leaking water - there may be an improper seal around the base or a crack in the porcelain. Unchecked leaks can lead to rust, corrosion, or water damage to your home.`}
        titleThree={`It Refills Constantly`}
        descriptionThree={`In minor cases, you can fix a constantly refilling toilet by jiggling the flapper valve to get it to close. However, if this issue happens regularly, you might need a new flapper valve or a new toilet.`}
      />
      <SubServicesAlt
        mainTitle={`Our Toilet Services`}
        titleOne={`Clog Removal`}
        descriptionOne={`No matter the cause, our experts can un-clog your toilet using proven methods and industry-leading equipment. Have your toilet running like normal in no time with our help.`}
        titleTwo={`Leak Repair`}
        descriptionTwo={`A leaking toilet is a serious issue, but our team can help. Whether your toilet is leaking from a seal or crack, we can reset it or replace it.`}
        titleThree={`New Installation`}
        descriptionThree={`Upgrading your toilet? Modern toilets flush and conserve water more efficiently, which can save money on your water bill. Our plumbers can install your new toilet right the first time.`}
      />
      {/* <Testimonials /> */}
      <ServiceAreas
        description={`toilets`}
        subDescription={`toilet repair or installation`}
      />
      <CTA title={`Get a Free Quote to Fix Your Toilet Now`} />
    </Layout>
  )
}

export default Toilets
